import React, { useState } from "react"
import expand from "../images/expand.svg"

import styles from "./contact.module.scss"

export default function Contact() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`${styles.contact} ${isOpen && styles.isOpen}`}>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.button}>
        <span className={styles.title}>Contact</span>
        <img className={styles.toggle} src={expand} alt="meer info" />
      </button>
      <div className={styles.extraInfo}>
        <div className={styles.text}>
          <h2>
            JULIE <br /> LIEVENS
          </h2>

          <p>
            <a href="mailto:julie@studio-connect.be">julie@studio-connect.be</a>
            <br />
            0478 73 20 06
          </p>

          <p>
            Wittemolenstraat 47
            <br />
            8200 Brugge
          </p>
        </div>
      </div>
    </div>
  )
}
