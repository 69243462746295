import React from "react"

import logo from "../images/logo.svg"
import styles from "./header.module.scss"

const Header = () => (
  <header className={styles.header}>
    <img className={styles.logo} src={logo} alt="Studio Connect" />
  </header>
)

export default Header
