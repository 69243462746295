import React, { useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Swipe from "react-easy-swipe"

import styles from "./slider.module.scss"
import Pager from "./pager"
import Contact from "./contact"

export default function Slider() {
  const { images } = useStaticQuery(graphql`
    {
      images: allFile(filter: { absolutePath: { regex: "/banner/" } }) {
        edges {
          node {
            id
            childImageSharp {
              id
              fluid(maxWidth: 2200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [slideTimeout, setSlideTimeout] = useState()

  function nextSlide() {
    let index = currentIndex + 1 >= images.edges.length ? 0 : currentIndex + 1
    setCurrentIndex(index)
  }

  function prevSlide() {
    let index =
      currentIndex - 1 >= 0 ? currentIndex - 1 : images.edges.length - 1
    setCurrentIndex(index)
  }

  useEffect(() => {
    clearTimeout(slideTimeout)
    setSlideTimeout(setTimeout(nextSlide, 6000))
    return clearTimeout(slideTimeout)
  }, [currentIndex])

  return (
    <div className={styles.wrapper}>
      <Swipe
        allowMouseEvents={true}
        onSwipeLeft={nextSlide}
        onSwipeRight={prevSlide}
      >
        <div className={styles.slider}>
          {images.edges.map(({ node }, index) => (
            <div
              active={currentIndex === index ? "true" : null}
              className={styles.slide}
              key={node.id}
            >
              <Img fluid={node.childImageSharp.fluid}></Img>
            </div>
          ))}
        </div>
      </Swipe>
      <div className={styles.sidebar}>
        <Contact></Contact>
        <Pager
          totalIndex={images.edges.length}
          currentIndex={currentIndex}
        ></Pager>
      </div>
    </div>
  )
}
