import React from "react"
import styles from "./pager.module.scss"

export default function Pager({ currentIndex, totalIndex }) {
  function formatNumber(number) {
    let formattedNumber = `${number}`
    if (formattedNumber.length < 2) formattedNumber = `0${formattedNumber}`
    return formattedNumber
  }

  return (
    <div className={styles.pager}>
      {formatNumber(currentIndex + 1)}/{formatNumber(totalIndex)}
    </div>
  )
}
