import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Slider from "../components/slider"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Header></Header>
    <Slider></Slider>
  </Layout>
)

export default IndexPage
